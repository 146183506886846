import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";

import { UserContextProvider } from "./context/context";

import FormScreen from "./pages/FormScreen";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import BlogsScreen from "./pages/BlogsScreen";
import Main from "./components/layout/Main";
import { Switch } from "antd";
import EventScreen from "./pages/EventScreen";
import BlogScreen from "./pages/BlogScreen";
import BlogLevel1Screen from "./pages/BlogLevel1";
import BlogLevel2Screen from "./pages/BlogLevel2";
function App() {
  // useEffect(() => {
  //   async function sendVersionToTelegram() {
  //     const version = packageJson.version;
  //     await axios.post(
  //       `${API_URL}core/ping`,
  //       {
  //         message: `${API_URL_FE} đã deploy phiên bản mới ${version}`,
  //         version,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           responseType: "blob",
  //           "x-auth-uuid": "",
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //   }
  //   sendVersionToTelegram();
  //   return () => sendVersionToTelegram();
  // }, []);

  return (
    <UserContextProvider>
      <Routes>
        <Route path="/form" element={<FormScreen />} />

        <Route element={<Main />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<BlogsScreen />} />
          <Route path="/event" element={<EventScreen />} />
          <Route path="/blogs/:id" element={<BlogScreen />} />
          <Route path="/blogs/:id/:idlevel1" element={<BlogLevel1Screen />} />
          <Route
            path="/blogs/:id/:idlevel1/:idlevel2"
            element={<BlogLevel2Screen />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
