import React, { useEffect, useState } from "react";
import brandImg1 from "../../assets/images/brand1.jpg";
import { FaTiktok, FaYoutube, FaFacebook } from "react-icons/fa";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../services/api/api.index";
import { API_URL } from "../../services/api/config";
import Toggle from "./Toggle";

const Main = ({ children }) => {
  const [openToggle, setOpenToggle] = useState(false);
  const [itemsForm, setItemsForm] = useState([]);
  const [itemsBlog, setItemsBlog] = useState([]);
  const [anotherLink, setAnotherLink] = useState(false);
  const [loading, setLoading] = useState(false);
  let path = useLocation();
  const navigate = useNavigate();
  const getDataForm = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/forms/external`,
        {
          display_status: "ACTIVE",
        }
      );
      setItemsForm(data);
    } catch (e) {
      setLoading(false);
    }
  };

  const getDataBlogs = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/blogs/external`,
        {
          depth: 2,
        }
      );
      setItemsBlog(data);
    } catch (e) {
      setLoading(false);
    }
  };
  const clickGolden = async (check) => {
    if (check) {
      navigate("/");
      setAnotherLink(true);
    } else {
      document?.getElementById("bookGolden")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(() => {
    getDataForm();
    getDataBlogs();
  }, []);

  useEffect(() => {
    if (anotherLink) {
      document?.getElementById("bookGolden")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setAnotherLink(false);
    }
  }, [anotherLink]);

  useEffect(() => {
    if (!anotherLink) {
      window.scrollTo(0, 0);
    }
  }, [path?.pathname]);

  const getOpen = () => {
    setOpenToggle(!openToggle);
  };
  return (
    <div className="container">
      <div className="homepage__navbar--mobile">
        <div className="homepage__navbar--mobile__left">
          <Link
            to={`/`}
            style={{ textDecoration: "none", color: "#fff" }}
            onClick={() => path?.pathname === "/" && window.scrollTo(0, 0)}
          >
            <img src={brandImg1} />
          </Link>
        </div>
        <Toggle open={openToggle} getOpen={() => getOpen()} />
      </div>
      <div
        className={
          !openToggle
            ? "homepage__navbar--mobile__menu--open"
            : "homepage__navbar--mobile__menu--close"
        }
      >
        <div
          className="homepage__navbar--mobile__link"
          onClick={() => setOpenToggle(!openToggle)}
        >
          <Link
            to={`/`}
            onClick={() => {
              path?.pathname === "/" && window.scrollTo(0, 0);
            }}
          >
            Trang chủ
          </Link>
        </div>
        <div
          className="homepage__navbar--mobile__link"
          onClick={() => setOpenToggle(!openToggle)}
        >
          <Link
            to={`/`}
            onClick={() => {
              path?.pathname === "/" && window.scrollTo(0, 0);
            }}
          >
            Giới thiệu
          </Link>
        </div>
        <div className="homepage__navbar--mobile__link">
          <Link
            state={itemsForm}
            to={`/event`}
            onClick={() => setOpenToggle(!openToggle)}
          >
            Các chương trình đang diễn ra
          </Link>
        </div>
        <div className="homepage__navbar--mobile__link">
          <Link
            to={`/blogs/${
              itemsBlog.find((x) => x.slug === "huong-dan-nguoi-moi")?.url_path
            }`}
            onClick={() => setOpenToggle(!openToggle)}
          >
            Dành cho người mới
          </Link>
        </div>
        <div className="homepage__navbar--mobile__link">
          <Link to={`/blogs`} onClick={() => setOpenToggle(!openToggle)}>
            Blog
          </Link>
        </div>
        <div className="homepage__navbar--mobile__link">
          <div
            onClick={() => {
              path?.pathname === "/" ? clickGolden(false) : clickGolden(true);
              setOpenToggle(!openToggle);
            }}
          >
            Sổ vàng lãi kép
          </div>
        </div>
      </div>
      <div className="homepage__navbar">
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            width: "1200px",
          }}
        >
          <div className="homepage__navbar__left">
            <Link
              to={`/`}
              style={{ textDecoration: "none", color: "#fff" }}
              onClick={() => path?.pathname === "/" && window.scrollTo(0, 0)}
            >
              <img src={brandImg1} />
            </Link>
          </div>
          <div className="homepage__navbar__right">
            <Link
              to={`/`}
              className="homepage__navbar__right__child box"
              onClick={() => {
                path?.pathname === "/" && window.scrollTo(0, 0);
              }}
            >
              <div className="glass"></div>
              <div>Trang chủ</div>
            </Link>

            <div
              className="homepage__navbar__right__child box"
              onClick={() => alert("Tính năng đang được phát triển!")}
            >
              <div className="glass"></div>
              <div>Giới thiệu</div>
            </div>
            <Link
              state={itemsForm}
              to={`/event`}
              className="homepage__navbar__right__child box"
            >
              <div className="glass"></div>
              Các chương trình đang diễn ra
              {/* <div className="homepage__navbar__right__child__items">
                <div
                  style={{
                    width: "100%",
                    background: "#fff",
                    borderRadius: "0 0 4px 4px",
                  }}
                >
                  {itemsForm.map((x, index) => (
                    <Link to={`/form?utm_source=${x.utm_source}`} key={index}>
                      {x.short_name}
                    </Link>
                  ))}
                </div>
              </div> */}
            </Link>
            <Link
              to={`/blogs/${
                itemsBlog.find((x) => x.slug === "huong-dan-nguoi-moi")
                  ?.url_path
              }`}
              className="homepage__navbar__right__child box"
            >
              <div className="glass"></div>
              <div>Dành cho người mới</div>
            </Link>
            <Link to={`/blogs`} className="homepage__navbar__right__child box">
              <div className="glass"></div>
              Blog
            </Link>

            <div
              className="homepage__navbar__right__child box"
              onClick={() =>
                path?.pathname === "/" ? clickGolden(false) : clickGolden(true)
              }
            >
              <div className="glass"></div>
              Sổ vàng lãi kép
            </div>
          </div>
        </div>
      </div>
      <div className="homepage">
        <div
          className={`children-page ${
            open ? "children-page--open" : "children-page--close"
          }`}
        >
          <Outlet />
        </div>
      </div>
      <div className="homepage__footer">
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "1200px",
          }}
        >
          <div className="homepage__footer__description">
            <h4>CÔNG TY CỔ PHẦN ĐẦU TƯ DNI VIỆT NAM</h4>
            <p>
              Địa chỉ: 56 đường số 1, Cityland Center Hills, Gò Vấp, Thành phố
              Hồ Chí Minh
            </p>
            <p>Email: it.dniinvest@gmail.com</p>
            <p>Hotline: 0356.670.756</p>
          </div>
          <div className="homepage__footer__link">
            <h4>Kết nối với chúng tôi</h4>
            <a href="https://www.facebook.com/dni.invest" target="_blank">
              <FaFacebook />
              &nbsp; Facebook
            </a>
            <a href="https://www.youtube.com/@DoanNamInvest" target="_blank">
              <FaYoutube />
              &nbsp; Youtube
            </a>
            <a href="https://www.facebook.com/dni.invest" target="_blank">
              <FaTiktok />
              &nbsp; Tiktok
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
