import React from "react";

import book from "../assets/images/book.png";
import bull from "../assets/images/bull.png";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";

import { FaLongArrowAltRight, FaRegCalendarAlt } from "react-icons/fa";
import Slider from "react-slick";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL, URL_IMAGE } from "../services/api/config";
import { api } from "../services/api/api.index";
import { Link } from "react-router-dom";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(book);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itemsForm, setItemsForm] = useState([]);
  const [itemsBlog, setItemsBlog] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const [dataSource, setDataSource] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const getData = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/golden-books/external`,
        {
          ...filter,
        }
      );
      setTotal(metadata?.totalItems);
      setLoading(false);
      let dataSourceFake = [...dataSource];
      if (data?.length > 0) {
        data.map((x) => dataSourceFake.push(x));
      }
      setDataSource(dataSourceFake);
    } catch (e) {
      setLoading(false);
    }
  };

  const getDataForm = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/forms/external`,
        {
          display_status: "ACTIVE",
        }
      );
      setItemsForm(data);
    } catch (e) {
      setLoading(false);
    }
  };

  const getDataBlogs = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/blogs/external`,
        {
          depth: 2,
        }
      );
      setItemsBlog(data);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page + 5 === filter.limit * filter.page) {
      setFilter({ ...filter, page: filter.page + 1 });
    }
  }, [page]);
  useEffect(() => {
    getData();
  }, [filter]);
  useEffect(() => {
    getDataForm();
    getDataBlogs();
  }, []);

  function stateChange(open) {
    setTimeout(() => {
      if (open) {
        setImage(bull);
      } else {
        setImage(book);
      }
    }, 300);
  }
  useEffect(() => {
    stateChange(open);
  }, [open]);

  return (
    <>
      <div className="homepage__block1" style={{ position: "relative" }}>
        <LazyLoadImage
          src={bull}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
        <div className="homepage__block1__description">
          <h4>Chào mừng đến với</h4>
          <h3>CỘNG ĐỒNG DNI INVEST</h3>
          <p>
            Lý tưởng chủ đạo là giúp 10.000 thanh niên Việt Nam tự do tài chính
          </p>
          <a href="https://t.me/dniinvest" target="_blank">
            ĐĂNG KÝ NGAY&nbsp;&nbsp;&nbsp;
            <span>
              <FaArrowRight color="white" />
            </span>
          </a>
        </div>
      </div>

      <div className="homepage__block5 blockTop">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>
            {itemsBlog.find((x) => x.slug === "huong-dan-nguoi-moi")?.name}
          </h4>
          <Link
            to={`/blogs/${
              itemsBlog.find((x) => x.slug === "huong-dan-nguoi-moi")?.url_path
            }`}
            className="newLinks"
          >
            Xem thêm&nbsp;
            <FaLongArrowAltRight />
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "top",
            justifyContent: "left",
            flexWrap: "wrap",
          }}
          className="blogs__items__block__parent"
        >
          {itemsBlog
            ?.find((x) => x.slug === "huong-dan-nguoi-moi")
            ?.children?.slice(0, 3)
            ?.map((x, index) => (
              <Link
                to={`/blogs/${x.url_path}`}
                key={index}
                className="blogs__items__block"
                style={{
                  marginLeft: index === 0 && "0",
                }}
              >
                <div className="blogs__items__block__image">
                  <LazyLoadImage
                    src={`${URL_IMAGE}` + x?.image_url}
                    href="block2"
                  />
                </div>

                <div className="blogs__items__block__content">
                  <div className="blogs__items__block__content__time">
                    <FaRegCalendarAlt />
                    &nbsp;
                    <span>
                      {moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className="blogs__items__block__content__title">
                    {x?.name}
                  </div>

                  <div className="blogs__items__block__content__footer">
                    <FaLongArrowAltRight />
                    &nbsp;Đọc thêm
                  </div>
                </div>
              </Link>
            ))}{" "}
        </div>
      </div>

      <div className="homepage__book" style={{ position: "relative" }}>
        <div id="bookGolden" style={{ marginBottom: "16px" }}></div>
        <div className="book">
          <div
            className={open ? "cover checked" : "cover"}
            onClick={() => {
              {
                page === 0 && open === true ? setOpen(false) : setOpen(true);
              }
            }}
          >
            <label htmlFor="checkbox-cover" style={{ zIndex: "10" }}>
              {image !== bull ? (
                <LazyLoadImage
                  src={image}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: image === bull && "0 16px 16px 0",
                    padding: image === bull && "16px",
                  }}
                />
              ) : (
                <div></div>
              )}
            </label>
          </div>

          <div
            className="page"
            style={{
              display: page < 3 ? "none" : "block",
              transform: "translateX(-100%)",
              zIndex: 1,
              borderRadius: "16px",
              padding: "16px",
              fontSize: "16px",
            }}
          >
            {page === 2 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: dataSource[0]?.description,
                }}
              ></div>
            )}
            {dataSource?.length > 0 &&
              dataSource.map(
                (x, index) =>
                  page === index + 3 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataSource[index + 1]?.description,
                      }}
                      key={index}
                    ></div>
                  )
              )}
          </div>

          {dataSource?.length > 0 &&
            dataSource.map((x, index) => (
              <div
                className={page === index + 2 ? "page checked" : "page"}
                id={`page${index + 1}`}
                style={{ zIndex: page > index + 2 ? 0 : 100 - index }}
                key={index}
              >
                {page === 0 ? (
                  <div className="front-page">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataSource[index]?.description,
                      }}
                    ></div>
                    <div className="nextBook" onClick={() => setPage(2)}>
                      <FaArrowRight size={29} />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="front-page">
                      {dataSource[index]?.images?.length > 0 && (
                        <Slider {...settings}>
                          {dataSource[index]?.images?.map((x, indexx) => (
                            <div
                              className="homepage__block1__slide__image"
                              key={indexx}
                            >
                              <LazyLoadImage
                                src={`${URL_IMAGE}` + x}
                                href="block2"
                              />
                            </div>
                          ))}
                        </Slider>
                      )}
                      {page < total && (
                        <div
                          className="nextBook"
                          onClick={() => setPage(index + 2)}
                        >
                          <FaArrowRight size={29} />
                        </div>
                      )}
                    </div>
                    <div
                      className="back-page"
                      onClick={() => console.log(page)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataSource[index + 1]?.description,
                        }}
                      ></div>
                      <div
                        className="prevBook"
                        onClick={() => {
                          setPage(page <= 2 ? 0 : index + 1);
                        }}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <FaArrowLeft size={29} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}

          <div className="back-cover"></div>
        </div>
        <div
          id="bookGolden2"
          style={{ position: "absolute", top: "3008px" }}
        ></div>
      </div>

      <div className="homepage__block5" style={{ marginTop: "24px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>{itemsBlog.find((x) => x.slug === "tin-tuc")?.name}</h4>
          <Link
            to={`/blogs/${
              itemsBlog.find((x) => x.slug === "tin-tuc")?.url_path
            }`}
            className="newLinks"
          >
            Xem thêm&nbsp;
            <FaLongArrowAltRight />
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "top",
            justifyContent: "left",
            flexWrap: "wrap",
          }}
          className="blogs__items__block__parent"
        >
          {itemsBlog
            ?.find((x) => x.slug === "tin-tuc")
            ?.children?.slice(0, 3)
            ?.map((x, index) => (
              <Link
                to={`/blogs/${x.url_path}`}
                key={index}
                className="blogs__items__block"
                style={{
                  marginLeft: index === 0 && "0",
                }}
              >
                <div className="blogs__items__block__image">
                  <LazyLoadImage
                    src={`${URL_IMAGE}` + x?.image_url}
                    href="block2"
                  />
                </div>

                <div className="blogs__items__block__content">
                  <div className="blogs__items__block__content__time">
                    <FaRegCalendarAlt />
                    &nbsp;{" "}
                    <span>
                      {moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className="blogs__items__block__content__title">
                    {x?.name}
                  </div>

                  <div className="blogs__items__block__content__footer">
                    <FaLongArrowAltRight />
                    &nbsp;Đọc thêm
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>

      <div className="homepage__block5 mrtpdb24">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>{itemsBlog.find((x) => x.slug === "hoat-dong")?.name}</h4>
          <Link
            to={`/blogs/${
              itemsBlog.find((x) => x.slug === "hoat-dong")?.url_path
            }`}
            className="newLinks"
          >
            Xem thêm&nbsp;
            <FaLongArrowAltRight />
          </Link>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "top",
            flexWrap: "wrap",
          }}
          className="blogs__items__block__parent"
        >
          {itemsBlog
            ?.find((x) => x.slug === "hoat-dong")
            ?.children?.slice(0, 3)
            ?.map((x, index) => (
              <Link
                to={`/blogs/${x.url_path}`}
                key={index}
                className="blogs__items__block"
                style={{
                  marginLeft: index === 0 && "0",
                }}
              >
                <div className="blogs__items__block__image">
                  <LazyLoadImage
                    src={`${URL_IMAGE}` + x?.image_url}
                    href="block2"
                  />
                </div>

                <div className="blogs__items__block__content">
                  <div className="blogs__items__block__content__time">
                    <FaRegCalendarAlt />
                    &nbsp;{" "}
                    <span>
                      {moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className="blogs__items__block__content__title">
                    {x?.name}
                  </div>

                  <div className="blogs__items__block__content__footer">
                    <FaLongArrowAltRight />
                    &nbsp;Đọc thêm
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
