import React, { useState } from "react";
const Toggle = ({ open,getOpen }) => {
  const sendToggle = () => {
    getOpen();
  };
  return (
    <>
      <div
        className={open ? "toggle-close" : "toggle-open"}
        onClick={() => sendToggle()}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};
export default Toggle;
