import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL, URL_IMAGE } from "../services/api/config";
import { api } from "../services/api/api.index";
import { Link, useLocation } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import moment from "moment";
import OverlaySpinner from "../components/overlaySpinner/OverlaySpinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
const BlogsScreen = () => {
  const [itemsBlog, setItemsBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const abc = useLocation();

  const getFirstDataBlogs = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/blogs/external`,
        {
          status: "ACTIVE",
          depth: 3,
        }
      );
      setItemsBlog(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFirstDataBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blogs__bread" style={{ marginTop: "36px" }}>
        <OverlaySpinner open={loading} text={""} />

        <h4 className="breadcrumbCss">
          <Link to="/">Trang chủ</Link> / Blog
        </h4>

        <div className="blogs__items">
          {itemsBlog.map((x, index) => (
            <Link
              to={`/blogs/${x.url_path}`}
              key={index}
              className="blogs__items__block"
            >
              <div className="blogs__items__block__image">
                <LazyLoadImage
                  src={`${URL_IMAGE}` + x?.image_url}
                  href="block2"
                />
              </div>

              <div className="blogs__items__block__content">
                <div className="blogs__items__block__content__time">
                  <FaRegCalendarAlt />
                  &nbsp;{moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
                </div>
                <div className="blogs__items__block__content__title">
                  {x?.name}
                </div>

                <div className="blogs__items__block__content__footer">
                  <FaLongArrowAltRight />
                  &nbsp;Đọc thêm
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogsScreen;
