/* eslint-disable */
import { Spin } from "antd";

const OverlaySpinner = ({ open, spin, onClickCallback, text, position }) => {
  const onClick = () => {
    if (onClickCallback) {
      onClickCallback();
    }
  };
  if (open) {
    return (
      <div
        className="overlaySpinner"
        onClick={() => onClick()}
        style={{ position: position ? position : "fixed" }}
      >
        <div className="overplayTransform">
          <Spin size="large" color="fff"></Spin>
        </div>
        {/* {text && (
          <div className="waveLoading">
            {text.length > 0 &&
              [...text].map((x, index) => (
                <span style={{ ["--c"]: index + 1 }} key={index}>
                  {text[index]}
                </span>
              ))}
          </div>
        )} */}
      </div>
    );
  } else {
    return null;
  }
};

export default OverlaySpinner;
