import React from "react";
import imgBlock2 from "../assets/images/block2.jpg";
import imgBlock3 from "../assets/images/block3.png";
import imgPost1 from "../assets/images/post1.png";
import imgPost2 from "../assets/images/post2.png";
import imgPost3 from "../assets/images/post3.jpg";
import imgPost4 from "../assets/images/post4.png";
import tuthien1 from "../assets/images/tuthien/tuthien1.jpg";
import tuthien2 from "../assets/images/tuthien/tuthien2.jpg";
import tuthien3 from "../assets/images/tuthien/tuthien3.jpg";
import tuthien4 from "../assets/images/tuthien/tuthien4.jpg";
import main1 from "../assets/images/main/main1.JPG";
import main2 from "../assets/images/main/main2.JPG";
import main3 from "../assets/images/main/main3.jpg";
import main4 from "../assets/images/main/main4.JPG";
import main5 from "../assets/images/main/main5.JPG";
import main6 from "../assets/images/main/main6.JPG";
import book from "../assets/images/book.png";
import bull from "../assets/images/bull.png";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { Dropdown, Space } from "antd";
import main7 from "../assets/images/main/main7.JPG";
import main8 from "../assets/images/main/main8.JPG";
import { FaTiktok, FaYoutube, FaFacebook } from "react-icons/fa";
import Slider from "react-slick";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import brandImg1 from "../assets/images/brand1.jpg";
import { API_URL, URL_IMAGE } from "../services/api/config";
import { api } from "../services/api/api.index";
import { Link, useLocation } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import moment from "moment";
const EventScreen = () => {
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="form__bread" style={{ marginTop: "36px" }}>
      <h4 className="breadcrumbCss">
        <Link to="/">Trang chủ</Link> / Các chương trình đang diễn ra
      </h4>

      <div className="form__items">
        {state?.map((x, index) => (
          <a
            href={`${window?.location?.origin}/form?utm_source=${x.utm_source}`}
            key={index}
            className="form__items__block"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            <div className="form__items__block__content">
              <div
                className="form__items__block__content__title"
                style={{ margin: "0", fontWeight: 600 }}
              >
                {x?.short_name}
              </div>
              <div
                className="form__items__block__content__time"
           
              >
                <FaRegCalendarAlt size={12} />
                &nbsp;{moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
              </div>
              <div className="form__items__block__content__footer">
                <FaLongArrowAltRight />
                &nbsp;Tìm hiểu thêm
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default EventScreen;
