import { Form, Input, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import brandImg from "../assets/images/brand.jpg";
import { api } from "../services/api/api.index";
import { API_URL } from "../services/api/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  validateEmail,
  newValidate,
} from "../utils/helpers/functions/textUtils";
import {
  notifyError,
  notifySuccess,
} from "../components/notification/index.jsx";
import { newValidateID } from "../utils/helpers/functions/textUtils.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
const FormScreen = () => {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [formInfo] = Form.useForm();
  const handleFinish = async (e) => {
    setLoadingSubmit(true);

    let newValue = {};

    let arrayItems = [...dataSource?.items];
    let fakeValue = [...dataSource?.items].map((x) => {
      return {
        ...x,
        value:
          x.code === "name"
            ? e.name?.trim()
            : x.code === "gender"
            ? e.gender
            : x.code === "target_usd"
            ? e.target_usd
            : x.code === "trade_account_for_contest"
            ? e.trade_account_for_contest?.trim()
            : x.code === "passview"
            ? e.passview?.trim()
            : x.code === "trade_app"
            ? e.trade_app
            : x.code === "trade_server"
            ? e.trade_server
            : x.code === "dob"
            ? Number(e.dob)
            : x.code === "email"
            ? e.email?.trim()
            : x.code === "phone"
            ? e?.phone?.replaceAll(" ", "")
            : x.code === "telegram_username"
            ? e.telegram_username?.trim()
            : x.code === "trade_accounts"
            ? e.trade_accounts?.trim()
            : x.code === "trade_capital"
            ? e.trade_capital
            : x.code === "zalo_name"
            ? e.zalo_name?.trim()
            : x.code === "zalo_phone"
            ? e.zalo_phone?.trim()
            : "",
      };
    });

    let newFakeValue = [...fakeValue];
    for (let i = 0; i < arrayItems.length; i++) {
      if (
        arrayItems[i]?.code &&
        e?.hasOwnProperty([arrayItems[i]?.code?.toLowerCase()]) &&
        e?.[arrayItems[i]?.code?.toLowerCase()]
      ) {
        newValue[arrayItems[i]?.code?.toLowerCase()] =
          e?.[arrayItems[i]?.code?.toLowerCase()];
        newFakeValue = fakeValue.map((x) =>
          x.code === arrayItems[i]?.code?.toLowerCase()
            ? { ...x, value: e?.[arrayItems[i]?.code?.toLowerCase()] }
            : x
        );
      }
    }

    for (let i = 0; i < newFakeValue.length; i++) {
      if (
        newFakeValue[i]?.code &&
        newValue?.hasOwnProperty([newFakeValue[i]?.code?.toLowerCase()]) &&
        newValue?.[newFakeValue[i]?.code?.toLowerCase()]
      ) {
        newFakeValue = newFakeValue.map((x) =>
          x.code === newFakeValue[i]?.code?.toLowerCase()
            ? { ...x, value: newValue?.[newFakeValue[i]?.code?.toLowerCase()] }
            : x
        );
      }
    }

    try {
      let data = await api.post(`${API_URL}api/v1/form-answers`, {
        form: dataSource.id,
        items: newFakeValue,
      });
      setLoadingSubmit(false);
      notifySuccess("Gửi yêu cầu thành công!");
      formInfo.resetFields();
      window.scrollTo(0, 0);
    } catch (e) {
      setLoadingSubmit(false);
      notifyError(e?.message);
    }
  };
  const [dataSource, setDataSource] = useState({});
  const getData = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/forms/external/${queryParameters.get("utm_source")}`,
        {}
      );
      setLoading(false);
      setDataSource(data);
    } catch (e) {
      setLoading(false);
    }
  };

  const count = async () => {
    try {
      let { data } = await api.post(`${API_URL}api/v1/reports/visit-page`, {});
    } catch (e) {}
  };

  useEffect(() => {
    getData();
  }, [queryParameters]);
  useEffect(() => {
    count();
  }, []);
  return (
    <div className="mainBackground">
      {/* <OverlaySpinner open={loadingSubmit} /> */}
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Spin color="fff" />
        </div>
      ) : dataSource?.code ? (
        <>
          <div className="formScreen__background">
            <LazyLoadImage  src={brandImg} alt="" />
          </div>
          <div className="formScreen">
            <Form
              form={formInfo}
              onFinish={handleFinish}
              className="formScreen__form"
              layout="vertical"
            >
              {dataSource?.description?.length > 0 && (
                <div className="formScreen__form__info">
                  <div className="formScreen__form__info__bar"></div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSource?.description,
                    }}
                  ></div>
                </div>
              )}

              {dataSource?.items?.map(
                (x) =>
                  x?.status === "ACTIVE" && (
                    <div className="formScreen__form__input">
                      <Form.Item
                        label={x.label}
                        name={x.code}
                        rules={[
                          x.required && {
                            required: true,
                            message: "Vui lòng không bỏ trống",
                          },
                          x.type === "email" && {
                            validator(rule, value) {
                              return new Promise((resolve, reject) => {
                                if (value && validateEmail(value.trim())) {
                                  resolve();
                                } else {
                                  reject("Vui lòng nhập đúng định dạng email");
                                }
                              });
                            },
                          },
                          x.name === "trade_account_for_contest" && {
                            validator(rule, value) {
                              return new Promise((resolve, reject) => {
                                if (value && newValidateID(value.trim())) {
                                  resolve();
                                } else {
                                  reject(
                                    "Vui lòng nhập đúng định dạng ID (Ví dụ: 123456789)"
                                  );
                                }
                              });
                            },
                          },
                          x.name === "dob" && {
                            validator(rule, value) {
                              return new Promise((resolve, reject) => {
                                if (
                                  !value ||
                                  (Number(value) > 1900 && Number(value) < 2023)
                                ) {
                                  resolve();
                                } else {
                                  reject("Vui lòng nhập đúng năm sinh");
                                }
                              });
                            },
                          },
                          x.type === "phone" && {
                            validator(rule, value) {
                              return new Promise((resolve, reject) => {
                                if (!value || newValidate(value.trim())) {
                                  resolve();
                                } else {
                                  reject(
                                    "Vui lòng nhập đúng định dạng số điện thoại"
                                  );
                                }
                              });
                            },
                          },
                        ]}
                      >
                        {x.type === "select" ? (
                          <Select
                            placeholder={x?.tooltip}
                            options={x.options}
                          />
                        ) : (
                          <Input
                            placeholder={x?.tooltip}
                            type={x.name === "dob" ? "text" : x.type}
                          />
                        )}
                      </Form.Item>
                    </div>
                  )
              )}

              <div className="formScreen__form__submit">
                <div onClick={() => formInfo.submit()}>Gửi thông tin</div>
              </div>
            </Form>
          </div>{" "}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              fontSize: "48px",
              color: "#fff",
              fontWeight: "700",
              width: "100%",
              textAlign: "center",
            }}
          >
            OOPS!
          </div>
          <div
            style={{
              fontSize: "36px",
              color: "#fff",
              width: "100%",
              textAlign: "center",
              marginTop: "12px",
            }}
          >
            Vui lòng kiểm tra lại đường dẫn, hoặc liên hệ bộ phận hỗ trợ.
          </div>
        </div>
      )}
    </div>
  );
};

export default FormScreen;
