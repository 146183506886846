import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL, URL_IMAGE } from "../services/api/config";
import { api } from "../services/api/api.index";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import moment from "moment";
import OverlaySpinner from "../components/overlaySpinner/OverlaySpinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
const BlogLevel1Screen = () => {
  const [dataSource, setDataSource] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const abc = useLocation();
  const getDataBlogs = async () => {
    setLoading(true);
    try {
      let { data, metadata } = await api.get(
        `${API_URL}api/v1/blogs/external/${params?.id}%2F${params?.idlevel1}`,
        {
          status: "ACTIVE",
          depth: 3,
        }
      );
      setDataSource(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataBlogs();
  }, [params]);
  // useEffect(() => {
  //   if (state?.level === 0) {
  //     setParentState(state);
  //   }
  // }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blogs__bread" style={{ marginTop: "36px" }}>
      <OverlaySpinner open={loading} text={""} />

      <h4 className="breadcrumbCss">
        <Link to="/">Trang chủ</Link> / <Link to="/blogs">Blog</Link>{" "}
        /&nbsp;
        <Link to={`/blogs/${dataSource?.parent_info?.url_path}`}>
          {dataSource?.parent_info?.name}
        </Link>&nbsp;
        /&nbsp; {dataSource?.name}
      </h4>

      <div className="blogs__items">
        <>
          <div className="blogs__subBlock__image">
            <LazyLoadImage
              src={`${URL_IMAGE}` + dataSource?.image_url}
              href="block2"
            />
          </div>
          <div className="blogs__subBlock__content__title">
            {dataSource?.name}
          </div>
          <div
            className="blogs__subBlock__content__des"
            dangerouslySetInnerHTML={{
              __html: dataSource?.content,
            }}
          ></div>
          {dataSource?.children?.map((x, index) => (
            <Link
              to={`/blogs/${x.url_path}`}
              key={index}
              className="blogs__items__block"
              style={{ marginTop: "16px" }}
            >
              <div className="blogs__items__block__image">
                <LazyLoadImage
                  src={`${URL_IMAGE}` + x?.image_url}
                  href="block2"
                />
              </div>

              <div className="blogs__items__block__content">
                <div className="blogs__items__block__content__time">
                  <FaRegCalendarAlt />
                  &nbsp;{moment(x?.created_at).format("hh:mm DD/MM/YYYY")}
                </div>
                <div className="blogs__items__block__content__title">
                  {x?.name}
                </div>

                <div className="blogs__items__block__content__footer">
                  <FaLongArrowAltRight />
                  &nbsp;Đọc thêm
                </div>
              </div>
            </Link>
          ))}
        </>
      </div>
    </div>
  );
};

export default BlogLevel1Screen;
